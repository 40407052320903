import { styled } from '@mui/material/styles';

import Paper from '@/components/ui/Paper/Paper';

export const Wrapper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(7),
  borderRadius: theme.shape.borderRadiusLarge,

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(5),
  },
}));

export const Partner = styled('li')({
  display: 'inline-flex',
  justifyContent: 'center',
  position: 'relative',
  height: 46,
  width: 140,

  img: {
    objectFit: 'scale-down',
    objectPosition: 'center',
  },
});
