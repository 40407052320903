import PropTypes from 'prop-types';
import React from 'react';

import B2B from '@/components/B2B/B2B';
import Footer from '@/components/Footer/Footer';
import PageHeader from '@/components/PageHeader/PageHeader';

const SimplifiedLayout = ({
  children,
  basketHidden = true,
  showFooter = false,
  languagePagePickerHidden = false,
  navigationHidden = true,
  loginMenuHidden = false,
  actionIcons,
  showB2BModal = true,
}) => {
  return (
    <>
      <div>
        <PageHeader
          basketHidden={basketHidden}
          languagePagePickerHidden={languagePagePickerHidden}
          navigationHidden={navigationHidden}
          actionIcons={actionIcons}
          loginMenuHidden={loginMenuHidden}
        />
        {children}
        {showFooter ? <Footer /> : null}
      </div>
      {showB2BModal && <B2B />}
    </>
  );
};

SimplifiedLayout.propTypes = {
  /** Content displayed */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  showFooter: PropTypes.bool,
  navigationHidden: PropTypes.bool,
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default SimplifiedLayout;
